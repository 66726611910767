import React from "react";
import checkBox from "../../img/checkbox_filled.svg";
import TextLine from "../atom/TextLine";
import styled from "styled-components";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";

interface CheckBoxProps {
  checked?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  text?: string;
  gap?: number;
  lineheight?: number;
  linked?: {
    text: string;
    onclick: () => void;
  }
}

const CheckBoxWithText: React.FC<CheckBoxProps> = (props) => {
  return (
    <StLine
      gap={props.gap}
      lineheight={props.lineheight}
    >
      <DivTwoPartImprove
        display="contents"
        onclick={() => {
          if (props.setChecked) {
            props.setChecked(!props.checked);
          }
        }}
        cursor="pointer"
      >
        {props.checked ? <img src={checkBox} /> : <div className="emptyBox" />}
      </DivTwoPartImprove>
      {props.linked ?
        <DivTwoPartImprove
          display="flex"
        >
          <TextLine
            fontcolor="#2276DC"
            textdeco="underline"
            text={props.linked.text}
            cursor={true}
            onclick={props.linked.onclick}
          />
          <p>{props.text}</p>
        </DivTwoPartImprove> 
        :
        <p>{props.text}</p>
      }
    </StLine>
  );
};

const StLine = styled.div<CheckBoxProps>`
  display: grid;
  grid-template-columns: 22px auto;
  align-items: center;
  gap: ${(props) => props.gap}px;
  line-height: ${(props) => props.lineheight}px;
  /* cursor: pointer; */
  .emptyBox {
    width: 22px;
    height: 22px;
    border: 1px solid #818181;
    border-radius: 4px;
    box-sizing: border-box;
  }
`;

export default CheckBoxWithText;
