import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";

const DonutGraph = ({ width, height, thick, degree, half, donut, data }) => {
  const datas = data;

  // const colors = ["#3C9C9C", "#BDBDBD", "#FFBC00", "#C83B38"];
  const colors = ["#C83B38", "#FFBC00", "#E9B1AF", "#BDBDBD"];
  const max =
    data?.abnormal +
    data?.cheat +
    data?.notTaking +
    data?.analysisError;
  const part = [
    {
      x: width / 2,
      y: height / 2,
      radius: 54, // 반지름
      startAngle: 270 - degree / 2, // 시작 각도
      endAngle: 270 - degree / 2 + (degree / max) * data?.cheat, // 끝나는 각도
    },
    {
      x: width / 2,
      y: height / 2,
      radius: 54,
      startAngle: 270 - degree / 2 + (degree / max) * data?.cheat,
      endAngle:
        270 -
        degree / 2 +
        (degree / max) * (data?.cheat + data?.abnormal),
    },
    {
      x: width / 2,
      y: height / 2,
      radius: 54,
      startAngle:
        270 -
        degree / 2 +
        (degree / max) * (data?.cheat + data?.abnormal),
      endAngle:
        270 -
        degree / 2 +
        (degree / max) *
          (data?.cheat +
            data?.abnormal +
            data?.analysisError),
    },
    {
      x: width / 2,
      y: height / 2,
      radius: 54,
      startAngle:
        270 -
        degree / 2 +
        (degree / max) *
          (data?.cheat +
            data?.abnormal +
            data?.analysisError),
      endAngle: 270 + degree / 2,
    },
  ];

  const degToRad = (degree) => {
    return (Math.PI / 180) * degree;
  };

  const text =
    data?.abnormal + data?.cheat + data?.notTaking + data?.analysisError;
  const state = "확인 필요";

  const draw = () => {
    const dpr = window.devicePixelRatio;
    const canvas = document?.getElementById("canvas");
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    const ctx = canvas?.getContext("2d");
    ctx.scale(dpr, dpr);
    ctx.beginPath();
    ctx.clearRect(0, 0, width, height);
    ctx.font = "52px Medium";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.fillText(text, width / 2, height / 2 + 17);
    ctx.font = "16px Medium";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.fillText(state, width / 2, height / 2 + 52);
    if (
      datas?.abnormal > 0 ||
      datas?.analysisError > 0 ||
      datas?.cheat > 0 ||
      datas?.normal > 0 ||
      datas?.notTaking > 0
    ) {
      for (let i = 0; i < 4; i++) {
        ctx.beginPath();
        ctx.strokeStyle = colors[i];
        ctx.lineWidth = 18;
        ctx.arc(
          part[i].x,
          part[i].y,
          part[i].radius,
          degToRad(part[i].startAngle),
          degToRad(part[i].endAngle),
          part[i].anticlockwise
        );
        ctx.stroke();
        ctx.closePath();
      }
    } else {
      ctx.beginPath();
      ctx.strokeStyle = "#EEF0F3";
      ctx.lineWidth = 18;
      ctx.arc(
        width / 2,
        height / 2,
        54,
        degToRad(270 - degree / 2),
        degToRad(270 + degree / 2)
      );
      ctx.stroke();
      ctx.closePath();
    }
    ctx.closePath();
  };

  useEffect(() => {
    draw();
    // eslint-disable-next-line
  }, [part, text]);

  return (
    <StDonutGraph
      id="canvas"
      width={width}
      height={height}
      thick={thick}
      degree={degree}
      $half={half}
      donut={donut}
    />
  );
};

const StDonutGraph = styled.canvas`
  /* width: ${(props) => props.width}px;
  height: ${(props) => (props.half ? props.height : props.height)}px; */
  width: 143px;
  height: 126px;
  display: flex;
  @media ${device.tabMob} {
    margin: auto;
  }
  @media ${device.pc} {
    width: 160px;
    height: 136px;
    /* margin-left: 56.6px; */
  }
`;

export default DonutGraph;
