import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useGetReportList from "../hooks/useGetReportList";

// styled components

// components
import ChartList from "./ChartList";
import { device } from "../hooks/device";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import ReportItemListBox from "../atomic/organism/ReportItemListBox";

const ReportDown = (events) => {
  const [clickAbnormal, setClickAbnormal] = useState(false);
  const [clickNotComplete, setClickNotComplete] = useState(false);
  const [clickNormal, setClickNormal] = useState(false);
  const [lang, setLang] = useState("kr");
  const testId = useParams("testId").testId;

  const { data } = useGetReportList({ lang, testId });
  const country = useRecoilValue(languageIs);
  const detectList = data?.testerList?.detectList;
  const normalList = data?.testerList?.normalList;
  const notTakingList = data?.testerList?.notTakingList;
  const analysisErrorList = data?.testerList?.analysisErrorList;
  const [midList, setMidList] = useState();

  const eventSummary = events?.events?.testEventSummary?.userRequireConfirm;

  const detectHeaderList = [
    { title: "이름", flex: "0 0 303px" },
    { title: "상태", flex: "0 0 240px" },
    { title: "확인요망", flex: "0 0 140px" },
    { title: "주의요망", flex: "0 0 140px" },
    { title: "총 이벤트", flex: "0 0 140px" },
    { title: "주변환경 스캔 이상여부", flex: "0 0 217px" },
  ];

  const midHeaderList = [
    { title: "이름", flex: "0 0 303px" },
    { title: "상태", flex: "0 0 240px" },
    { title: "확인요망", flex: "0 0 140px" },
    { title: "주의요망", flex: "0 0 140px" },
    { title: "총 이벤트", flex: "0 0 140px" },
    { title: "주변환경 스캔 이상여부", flex: "0 0 217px" },
  ];

  const normalHeaderList = [
    { title: "이름", flex: "0 0 303px" },
    { title: "상태", flex: "0 0 240px" },
    { title: "확인요망", flex: "0 0 140px" },
    { title: "주의요망", flex: "0 0 140px" },
    { title: "총 이벤트", flex: "0 0 140px" },
    { title: "주변환경 스캔 이상여부", flex: "0 0 217px" },
  ];

  const pageListCount = 10;

  useEffect(() => {
    if (country === "/ko") {
      setLang("kr");
    } else {
      setLang("en");
    }
  }, [country]);

  useEffect(() => {
    if (notTakingList) {
      setMidList([...analysisErrorList, ...notTakingList]);
    }
  }, [notTakingList, analysisErrorList]);

  return (
    <StBack>
      <div className="body">
        <ReportItemListBox
          listTitle={
            "확인 요망 (" +
            eventSummary?.cheat +
            ") / 주의 요망 (" +
            eventSummary?.abnormal +
            ")"
          }
          nothingMessage="관련 응시자가 없음"
          headerTextArr={detectHeaderList}
          header_background_color="#F0F1F2"
          listCount={detectList?.length === undefined ? 0 : detectList.length}
          pageListCount={pageListCount}
          reportType="detect"
        >
          {detectList?.length > 0 && (
            <>
              {detectList?.map((value, index) => (
                <ChartList
                  key={index + 1}
                  check={value?.check}
                  testerId={value?.testerId}
                  testId={testId}
                  name={value?.testerName}
                  abnormals={value?.abnormals}
                  cheats={value?.cheats}
                  id={value?.video_id}
                  reportIndex={index}
                  reportType="detect"
                  pageListCount={pageListCount}
                  isVerifiedEnvironment={value?.isVerifiedEnvironment}
                  isManualShot={value?.isManualShot}
                />
              ))}
            </>
          )}
        </ReportItemListBox>
        <ReportItemListBox
          listTitle={
            "분석오류 (" +
            eventSummary?.analysisError +
            ") / 미완료 (" +
            eventSummary?.notTaking +
            ")"
          }
          nothingMessage="관련 응시자가 없음"
          headerTextArr={midHeaderList}
          header_background_color="#F0F1F2"
          listCount={midList?.length === undefined ? 0 : midList.length}
          reportType="mid"
        >
          {midList?.length > 0 && (
            <>
              {midList?.map((value, index) => (
                <ChartList
                  key={index + 1}
                  check={value?.check}
                  testerId={value?.testerId}
                  testId={testId}
                  name={value?.testerName}
                  abnormals={value?.abnormals}
                  cheats={value?.cheats}
                  reportIndex={index}
                  reportType="mid"
                  pageListCount={pageListCount}
                  isVerifiedEnvironment={value?.isVerifiedEnvironment}
                  isManualShot={value?.isManualShot}
                />
              ))}
            </>
          )}
        </ReportItemListBox>
        <ReportItemListBox
          listTitle={"정상 응시 (" + eventSummary?.normal + ")"}
          nothingMessage="관련 응시자가 없음"
          headerTextArr={normalHeaderList}
          header_background_color="#F0F1F2"
          listCount={normalList?.length === undefined ? 0 : normalList.length}
          reportType="normal"
        >
          {normalList?.length > 0 && (
            <>
              {normalList?.map((value, index) => (
                <ChartList
                  key={index + 1}
                  check={value?.check}
                  testerId={value?.testerId}
                  testId={testId}
                  name={value?.testerName}
                  abnormals={value?.abnormals}
                  cheats={value?.cheats}
                  reportIndex={index}
                  reportType="normal"
                  pageListCount={pageListCount}
                  isVerifiedEnvironment={value?.isVerifiedEnvironment}
                  isManualShot={value?.isManualShot}
                />
              ))}
            </>
          )}
        </ReportItemListBox>
      </div>
    </StBack>
  );
};

const StBack = styled.div`
  @media ${device.tabMob} {
    width: 100%;
    background-color: #f0f1f2;
    margin: 0 auto;
    padding: 24px 24px 44px;
    box-sizing: border-box;
    .nothing {
      display: flex;
      width: 100%;
      height: 90px;
      justify-content: center;
      align-items: center;
      color: #bdbdbd;
      font-size: 14px;
    }
    .body {
      display: grid;
      flex-direction: column;
      width: 100%;
      margin: 0 auto 0 auto;
      gap: 44px;
      .boxForEachState {
        display: grid;
        flex-direction: column;
        gap: 12px;
        .titleBox {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          .middle {
            font-size: 18px;
            font-family: "Bold";
            line-height: 21px;
          }
        }
      }
    }
    .griding {
      display: grid;
      grid-template-columns: 100%;
      gap: 12px;
    }
    .stImg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 20px;
      cursor: pointer;
    }
  }
  @media ${device.pc} {
    width: 100vw;
    max-width: 100%;
    min-width: 1440px;
    background-color: #f0f1f2;
    padding: 60px 0 80px;
    .body {
      display: grid;
      flex-direction: column;
      gap: 60px;
      width: 1228px;
      margin: 0 auto;
      .boxForEachState {
        display: grid;
        flex-direction: column;
        gap: 24px;
      }
    }
    .nothing {
      display: flex;
      width: 100%;
      height: 90px;
      justify-content: center;
      align-items: center;
      color: #bdbdbd;
    }
    .griding {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 20px;
    }
    .stImg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 15px;
      cursor: pointer;
    }
    .titleBox {
      display: flex;
      align-items: center;
      .middle {
        font-size: 22px;
        font-family: "Bold";
        line-height: 33px;
      }
    }
  }
`;

export default ReportDown;
