import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD, ModalHeaderC } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { device } from "../hooks/device";
import useModalMust from "../hooks/useModalMust";
// Noah
import greyX from "../img/greyX.svg";

const RawData = ({ setRawDataState, setRawDataModal }) => {
  useModalMust({ setRawDataModal });
  return (
    <>
      <ModalBackD />
      <StRawData>
        {/**
         * 티켓링크 : https://www.notion.so/edint/WEB-874c58ac5c2642d9951a1a19c6f7bf0e?pvs=4
         * 주석작성자 : Noah
         * 주석작성일자 : 2024.05.08
         * 티켓내용 : 결과 리포트 → 감지된 이벤트 내역 관련
         * 주석설명 : 원본 내역 포함 클릭 시 모달에 X버튼 추가 -> 취소버튼으로 변경 (2024.05.20)
         */}
        <ModalHeaderD title="원본 내역 포함해서 보기" />
        {/* <ModalHeaderC
          title="원본 내역 포함해서 보기" 
          onClick={() => {
            setRawDataState(false);
            window.history.back();
            setRawDataModal(false);
          }}
        /> */}
        <div className="bodyBox">
          프록토매틱에서 감지한 모든 이벤트의 원본 내역을 포함하여 확인할 수
          있어요. 다만, 가공 처리가 이루어지지 않은 원본 데이터 특성 상 중복
          감지, 오감지 등 다수의 불필요한 이벤트들이 함께 보여질 수 있으므로
          해당 보기를 선택할 때 주의가 필요해요.
        </div>
        <div className="listBox">
          <div>※ &nbsp;변경되는 보기 항목</div>
          <li>
            <span>감지된 이벤트 내역</span>
          </li>
          <li>
            <span>감지된 이벤트 영상</span>
          </li>
          <li>
            <span>신체/환경 요소 별 감지 데이터</span>
          </li>
        </div>
        <div className="buttonBoxs">
          <div
            className="buttonBox"
            onClick={() => {
              setRawDataState(false);
              window.history.back();
              setRawDataModal(false);
            }}
          >
            취소
          </div>
          <div
            className="buttonBox confirmBox"
            onClick={() => {
              setRawDataState(true);
              window.history.back();
              setRawDataModal(false);
            }}
          >
            확인
          </div>
        </div>
      </StRawData>
    </>
  );
};

const StRawData = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  z-index: 53;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.08);
  .bodyBox {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
  }
  .listBox {
    div {
      font-size: 16px;
      font-family: "semiBold";
      line-height: 24px;
      margin-bottom: 4px;
    }
    li {
      color: #444;
      line-height: 24px;
      font-family: "regular";
      font-size: 14px;
      margin: auto 0;
      ::marker {
        margin: auto;
      }
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .buttonBoxs {
    display: flex;
    justify-content: center;
    align-items: center;
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "medium";
      margin: 0 auto;
      cursor: pointer;
    }
  }
  
  @media ${device.pc} {
    display: grid;
    gap: 48px;
    width: 580px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    .buttonBox {
      width: 204px;
      height: 52px;
      display: flex;
      font-size: 17px;
      line-height: 25px;
      border: 1px solid #000;
      border-radius: 43px;
      cursor: pointer;
    }
    .buttonBox.confirmBox {
      background-color: #20315b;
      color: #FFFFFF;
    }
  }
  @media ${device.tabMob} {
    display: grid;
    gap: 20px;
    width: 344px;
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0%);
    padding: 24px;
    border-radius: 24px;
    .bodyBox {
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
    }
    .buttonBox {
      margin-top: 4.5px;
      font-size: 18px;
      line-height: 27px;
      color: #0072de;
    }
    .buttonBox.confirmBox {
      background-color: #FFFFFF;
      color: #0072de;
    }
  }
`;

export default RawData;
