import React, { Children, ReactNode } from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface FlexDivProp {
  children: ReactNode;
  font_color?: string;
  font_family?: string;
  font_size?: number;
  display?: string;
  pc_display?: string;
  pc_justify?: string;
  pc_align_items?: string;
  pc_font_size?: number;
  pc_font_family?: string;
  pc_lineheight?: number;
  pc_margin?: string;
  pc_gap?: number;
  pc_text_align?: string;
  tab_display?: string;
  tab_justify?: string;
  tab_align_items?: string;
  tab_font_size?: number;
  tab_font_family?: string;
  tab_lineheight?: number;
  tab_margin?: string;
  tab_gap?: number;
  tab_text_align?: string;
  mobile_display?: string;
  mobile_justify?: string;
  mobile_align_items?: string;
  mobile_font_size?: number;
  mobile_font_family?: string;
  mobile_lineheight?: number;
  mobile_margin?: string;
  mobile_gap?: number;
  mobile_text_align?: string;
  mobile_margin_top?: number;
  mobile_grid_template_columns?: string;
  tab_margin_top?: number;
  tab_grid_template_columns?: string;
  pc_flex_direction?: string;
  pc_width?: string;
  pc_margin_top?: number;
  pc_background_color?: string;
  pc_box_sizing?: string;
  pc_border_radius?: number;
  pc_padding?: string;
  pc_grid_template_column?: string;
}

const DivThreePart: React.FC<FlexDivProp> = (props) => {
  // console.log(Children.count(children));
  return (
    <StDiv
      font_family={props.font_family}
      font_color={props.font_color}
      font_size={props.font_size}
      display={props.display}
      pc_display={props.pc_display}
      pc_align_items={props.pc_align_items}
      pc_font_size={props.pc_font_size}
      pc_lineheight={props.pc_lineheight}
      pc_margin={props.pc_margin}
      pc_gap={props.pc_gap}
      pc_text_align={props.pc_text_align}
      pc_justify={props.pc_justify}
      tab_display={props.tab_display}
      tab_align_items={props.tab_align_items}
      tab_font_size={props.tab_font_size}
      tab_font_family={props.tab_font_family}
      tab_lineheight={props.tab_lineheight}
      tab_margin={props.tab_margin}
      tab_gap={props.tab_gap}
      tab_text_align={props.tab_text_align}
      tab_justify={props.tab_justify}
      mobile_display={props.mobile_display}
      mobile_align_items={props.mobile_align_items}
      mobile_font_size={props.mobile_font_size}
      mobile_font_family={props.mobile_font_family}
      mobile_lineheight={props.mobile_lineheight}
      mobile_margin={props.mobile_margin}
      mobile_gap={props.mobile_gap}
      mobile_text_align={props.mobile_text_align}
      mobile_justify={props.mobile_justify}
      mobile_margin_top={props.mobile_margin_top}
      mobile_grid_template_columns={props.mobile_grid_template_columns}
      tab_margin_top={props.tab_margin_top}
      tab_grid_template_columns={props.tab_grid_template_columns}
      pc_flex_direction={props.pc_flex_direction}
      pc_width={props.pc_width}
      pc_margin_top={props.pc_margin_top}
      pc_background_color={props.pc_background_color}
      pc_box_sizing={props.pc_box_sizing}
      pc_border_radius={props.pc_border_radius}
      pc_padding={props.pc_padding}
      pc_grid_template_column={props.pc_grid_template_column}
    >
      {props.children}
    </StDiv>
  );
};

const StDiv = styled.div<FlexDivProp>`
  font-size: ${(props) => props.font_size}px;
  font-family: ${(props) => props.font_family};
  color: ${(props) => props.font_color};
  display: ${(props) => props.display};
  @media ${device.pc} {
    display: ${(props) => props.pc_display};
    justify-content: ${(props) => props.pc_justify};
    align-items: ${(props) => props.pc_align_items};
    gap: ${(props) => props.pc_gap}px;
    font-size: ${(props) => props.pc_font_size}px;
    font-family: ${(props) => props.pc_font_family};
    line-height: ${(props) => props.pc_lineheight}px;
    margin: ${(props) => props.pc_margin};
    text-align: ${(props) => props.pc_text_align};
    flex-direction: ${(props) => props.pc_flex_direction};
    width: ${(props) => props.pc_width};
    margin-top: ${(props) => props.pc_margin_top}px;
    background-color: ${(props) => props.pc_background_color};
    padding: ${(props) => props.pc_padding};
    border-radius: ${(props) => props.pc_border_radius}px;
    box-sizing: ${(props) => props.pc_box_sizing};
    grid-template-columns: ${(props) => props.pc_grid_template_column};
  }
  @media ${device.tablet} {
    display: ${(props) => props.tab_display};
    justify-content: ${(props) => props.tab_justify};
    align-items: ${(props) => props.tab_align_items};
    gap: ${(props) => props.tab_gap}px;
    font-size: ${(props) => props.tab_font_size}px;
    font-family: ${(props) => props.tab_font_family};
    line-height: ${(props) => props.tab_lineheight}px;
    margin: ${(props) => props.tab_margin};
    text-align: ${(props) => props.tab_text_align};
    margin-top: ${(props) => props.tab_margin_top}px;
    grid-template-columns: ${(props) => props.tab_grid_template_columns};
  }
  @media ${device.mobile} {
    display: ${(props) => props.mobile_display};
    justify-content: ${(props) => props.mobile_justify};
    align-items: ${(props) => props.mobile_align_items};
    gap: ${(props) => props.mobile_gap}px;
    font-size: ${(props) => props.mobile_font_size}px;
    font-family: ${(props) => props.mobile_font_family};
    line-height: ${(props) => props.mobile_lineheight}px;
    margin: ${(props) => props.mobile_margin};
    text-align: ${(props) => props.mobile_text_align};
    margin-top: ${(props) => props.mobile_margin_top}px;
    grid-template-columns: ${(props) => props.mobile_grid_template_columns};
  }

  display: flex;
`;

export default DivThreePart;
